<template>
  <div class="LdxPc">
    <div class="tabbar" :class="navBarFixed ? 'navBarFixed' : ''">
      <img @click="goback" src="../../../../static/common/logo.png" alt="" />
    </div>
    <div class="banner">
      <img src="/subject/Ldx/dl1.png" class="dl1" />
      <img src="/subject/Ldx/dl2.png" class="dl2" />
    </div>
    <div class="part-1">
      <img src="/subject/Ldx/sec1-title.png" alt="" class="tit" />
      <ul class="advs5">
        <li data-aos="slide-right">
          <img src="/subject/Ldx/icon_01.png" />
        </li>
        <li data-aos="slide-right">
          <img src="/subject/Ldx/jiantou.png" class="jiantou" />
        </li>
        <li data-aos="slide-right">
          <img src="/subject/Ldx/icon_02.png" />
        </li>
        <li data-aos="slide-right">
          <img src="/subject/Ldx/jiantou.png" class="jiantou" />
        </li>
        <li data-aos="slide-right">
          <img src="/subject/Ldx/icon_03.png" class="icon-item" />
        </li>
      </ul>
      <p class="p1">
        好友每交易1手，您可获得<b>5美元</b>的现金奖励，奖励可提现； <br />
        邀请越多，赢得越多，最高可达<b>6688美元</b>现金奖励
      </p>
      <a @click="openUser" class="btnReal"></a>
      <p class="p2">保存邀请海报，分享好友齐赚钱</p>
    </div>
    <div class="part-2">
      <img class="part2tit" src="/subject/Ldx/sec2-title.png" alt="" />
      <span
        class="rules"
        @click="
          () => {
            activityDetailsDialog = true;
          }
        "
        >活動詳情 ></span
      >
      <img src="/subject/Ldx/big.png" class="bigsec" />
      <p>
        <!-- 选对方法，智慧理财<br /> -->
        好友交易您收益，马上行动起来
      </p>
      <div class="btnBox">
        <a class="live800" @click="openKefu"></a>
        <a @click="openUser" class="btnReal"></a>
      </div>
    </div>
    <div class="part-3">
      <img class="part2tit" src="/subject/Ldx/sec3-title.png" alt="" />
      <div class="twoPart">
        <div data-aos="slide-left">
          <img src="/subject/Ldx/eggs.png" />
        </div>
        <div data-aos="slide-right">
          <img src="/subject/Ldx/words.png" />
          <a @click="openUser" class="btnInvite"></a>
          <p>邀请1位好友，得最高<b>$6688，邀请人数无上限</b></p>
        </div>
      </div>
    </div>
    <div class="part-4">
      <img class="part2tit" src="/subject/Ldx/sec4-title.png" alt="" />
      <div class="boxContent">
        <div data-aos="slide-left">
          <img src="/subject/Ldx/phone.png" width="555" />
        </div>
        <div data-aos="slide-left" class="jiantour">
          <img width="110" src="/subject/Ldx/jiantou2.png" />
        </div>
        <div data-aos="slide-left">
          <ul>
            <li><img width="279" src="/subject/Ldx/pic1.png" @click="openKefu" /></li>
            <li><img width="279" src="/subject/Ldx/pic2.png" @click="openKefu" /></li>
            <li><img width="279" src="/subject/Ldx/pic3.png" @click="openKefu" /></li>
            <li><img width="279" src="/subject/Ldx/pic4.png" @click="openKefu" /></li>
          </ul>
        </div>
      </div>
      <div class="appCode">
        <div class="appCode_left">
          <div class="codeBody">
            <div>MT4账户专用版本</div>
            <div class="codeBox">
              <div class="codeItem">
                <Qrcode :link="iosMt4DownloadUrl" :size="168" :iconSize="30"/>
                <div>iOS</div>
              </div>
              <div class="codeItem">
                <Qrcode :link="androidMt4DownloadUrl" :size="168"/>
                <div>Android</div>
              </div>
            </div>
          </div>
          <div class="codeBody">
            <div>MT5账户专用版本</div>
            <div class="codeBox">
              <div class="codeItem">
                <Qrcode :link="iosMt5DownloadUrl" :size="168"/>
                <div>iOS</div>
              </div>
              <div class="codeItem">
                <Qrcode :link="androidDownloadUrl" :size="168"/>
                <div>Android</div>
              </div>
            </div>
          </div>
        </div>
        <div class="appCode_right">
          <div class="appCode_right-tips">
            <div class="appCode_right-tips-item">
              <p>1、MT4版本用MT4账户，MT5版本用MT5账户，</p>
              <p>两者不互通；</p>
            </div>
            <div class="appCode_right-tips-item">
              <p>2、如无法确定所用账户的MT版本，请联系</p>
              <p><span class="appCode_right-tips-serve" @click="openKefu">在线客服</span>查询。</p>
            </div>
          </div>
          <div class="appCode_right-serve">
            <img src="/subject/Ldx/serve.png" width="82" height="82" @click="openKefu" />
            <div>在线客服</div>
          </div>
        </div>
      </div>
      <!-- <p class="lastP">
        红狮启富App<br />
        您的智富伙伴<br />
        更方便快捷的交易，更及时的新闻资讯和优惠信息
      </p> -->
    </div>
    <contactusVue></contactusVue>
    <div v-if="activityDetailsDialog" class="activityDetailsDialog">
      <img
        @click="
          () => {
            activityDetailsDialog = false;
          }
        "
        src="/subject/worldCup/closer2.png"
        alt=""
      />
      <div>
        <h3>· 活动时间：</h3>
        <p>长期有效；</p>
        <h3>1. 参与条件：</h3>
        <p>
          推荐人与被推荐人都为本平台激活用户<br />推荐人完成推荐（与被推荐人绑定邀请码或邀请关系）
        </p>
        <h3>2. 活动奖励:</h3>
        <p>
          (1) 推荐人<br />
          a.被推荐人自完成开户起90天内，被推荐人每交易1手，推荐人即可获得5美元/手的现金奖励（不足1手，按比例发放），最多可获得6688美元的现金奖励；<br />
          b.推荐人所获得的现金奖励按自然月领取，推荐人仅需保持账户活跃状态，即账户每个自然月都有真实交易记录（开仓、平仓或持仓都可以，无手数要求）即可持续参与活动，否则，则视为自动退出该活动。<br />
          c.推荐人可推荐多人参与，被推荐人只能有一个对应的推荐人。若推荐人成功推荐多位被推荐人，那么5美元/手现金奖励可累计领取。<br />
        </p>
        <p>
          3、推荐方式： ①
          推荐人可复制分享链接或保存邀请海报，通过微信、QQ、邮箱等方式发送给好友，好友在专属推荐链接内开立真实账户即可完成推荐（如果遇到开启异常，可以复制链接到浏览器打开）
        </p>
        <p>
          ②
          推荐人将邀请码发送给好友，好友开立真实账户后，在用户中心内绑定邀请码后即可完成推荐。
        </p>
        <p>4、活动奖励：</p>
        <p>
          ① 现金奖励将以美元现金的形式发放至客户的用户中心；<br />
          ②
          现金奖励提取后发放至客户MT4真实交易账户中，该笔资金可提现亦可继续用于交易；<br />
          ③ 现金奖励每月1-7日进行审核，审核完成之后，符合活动条件方可发放
        </p>
        <h3>· 注意事项</h3>
        <p>
          1. 参加原邀请好友活动的客户按照原活动规则执行，如有疑问请咨询客服；<br />
          2.
          同一真实交易账户不可被多次邀请；每位被推荐人仅能为推荐人带来从注册开户开始，有效期3个月（90天）的现金奖励；<br />
          3.
          交易手数的计算：本活动只针对伦敦金伦敦银交易的手数计算，开仓一手再平仓一手，如此来回为一手；<br />
          4. 我平台保留随时修订、暂停、终止本活动及相关规则条款之权利及解释权。
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import ContactusVue from "../../Contactus.vue";
import Qrcode from '../../QrCode/index.vue'
import { mapState  } from "vuex";
export default {
  name: "LdxPc",
  components: {
    ContactusVue,
    Qrcode,
  },
  data() {
    return {
      hoverControl: "",
      activityDetailsDialog: false,
      navBarFixed: false,
      scroll: "",
    };
  },
  computed: {
    ...mapState('common', {
      androidMt4DownloadUrl: 'androidMt4DownloadUrl',
      androidDownloadUrl: 'androidDownloadUrl',
      iosMt4DownloadUrl: 'iosMt4DownloadUrl',
      iosMt5DownloadUrl: 'iosMt5DownloadUrl',
    })
  },
  methods: {
    // 返回上级页面
    goback() {
      window.location.href = sessionStorage.getItem("craig");
    },
    // 控制顶部的定位
    handleScroll() {
      this.scroll =
        document.documentElement.scrollTop + document.body.scrollTop; //获取滚动后的高度
      if (this.scroll > 90) {
        this.navBarFixed = true;
      } else {
        this.navBarFixed = false;
      }
    },
    openUser() {
      window.open("https://www.rlcproltd.net/uc/specialEvent/inviteReward");
    },
    openKefu() {
      window.open(
        this.$parent.live800UrlUc
        // "https://chat32.live800.com/live800/chatClient/chatbox.jsp?companyID=215184&configID=35519&jid=5377035377"
      );
    },
  },
  created() {
    this.$emit("controlShow", true);
    // 判断是从A前还是A后跳转过来
    if (!sessionStorage.getItem("craig")) {
      if (!this.$route.query.craig) {
        sessionStorage.setItem("craig", window.location.origin);
      } else {
        sessionStorage.setItem("craig", this.$route.query.craig);
      }
    }
    if (document.body.clientWidth < 500) {
      this.$router.push({
        name: "LdxWap",
      });
    }
  },
  mounted() {
    document.title = "顺顺发发，邀友共享"
    window.addEventListener("scroll", this.handleScroll);
  },
};
</script>

<style lang="less" scoped>
.tabbar {
  background-color: #ffffff;
  padding: 10px 30px;
  img {
    width: 130px;
  }
}
.navBarFixed {
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 999;
}
img {
  display: block;
}
.LdxPc {
  width: 100%;
  .banner {
    width: 100%;
    height: 682px;
    background: url("/subject/Ldx/banner.jpg") no-repeat center;
    background-size: cover;
    position: relative;
    .dl1 {
      position: absolute;
      top: 160px;
      left: 20px;
    }
    .dl2 {
      position: absolute;
      top: 160px;
      right: 20px;
    }
  }
  .part-1 {
    overflow: hidden;
    background: #398572;
    padding-bottom: 40px;
    padding-top: 40px;
    .p1 {
      color: #fde0c0;
      text-align: center;
      font-size: 32px;
      margin: 80px 0 30px;
      line-height: 52px;
      b {
        color: #6df7ff;
        font-weight: normal;
      }
    }
    .p2 {
      color: #fde0c1;
      font-size: 24px;
      text-align: center;
    }
    .btnReal {
      display: block;
      margin: 0 auto;
      background-image: url("/subject/Ldx/btn1.png");
      background-position-x: center;
      background-position-y: top;
      background-repeat: no-repeat;
      width: 484px;
      height: 154px;
    }
    .tit {
      width: 882px;
      height: 140px;
      margin: 0 auto;
      padding-bottom: 40px;
    }
    .advs5 {
      text-align: center;
      li {
        display: inline-block;
        vertical-align: top;
        width: 300px;
        .jiantou {
          position: relative;
          top: 160px;
          margin-left: 60px;
        }
      }
    }
  }
  .part-2 {
    background: #eef3e2;
    padding-bottom: 30px;
    padding-top: 30px;
    overflow: hidden;
    .part2tit {
      width: 882px;
      height: 140px;
      margin: 0 auto;
    }
    .rules {
      display: block;
      cursor: pointer;
      color: #286d5c;
      text-decoration: underline;
      font-size: 32px;
      width: 185px;
      padding: 6px;
      margin: 15px auto;
    }
    p {
      color: #2a6a50;
      font-size: 40px;
      text-align: center;
      line-height: 50px;
      margin-top: 60px;
    }
    .btnBox {
      text-align: center;
      .live800 {
        width: 484px;
        height: 154px;
        display: inline-block;
        background-image: url("/subject/Ldx/live800.png");
        background-position-x: center;
        background-position-y: top;
        background-repeat: no-repeat;
        background-size: cover;
      }
      .btnReal {
        display: inline-block;
        width: 484px;
        height: 154px;
        background-image: url("/subject/Ldx/btn-r.png");
        background-position-x: center;
        background-position-y: top;
        background-repeat: no-repeat;
        background-size: cover;
      }
    }
  }
  .part-3 {
    background: #348172;
    padding-top: 40px;
    padding-bottom: 40px;
    .part2tit {
      width: 1073px;
      height: 140px;
      margin: 0 auto;
    }
    .twoPart {
      text-align: center;
      div {
        display: inline-block;
        vertical-align: top;
        p {
          color: #fde0c1;
          font-size: 22px;
          b {
            font-weight: normal;
            color: #ffb847;
          }
        }
      }
      div:nth-child(2) {
        padding-top: 105px;
      }
      .btnInvite {
        width: 484px;
        height: 154px;
        background: url("/subject/Ldx/btn_invite.png") no-repeat center;
        display: block;
        margin: 0 auto;
      }
    }
  }
  .part-4 {
    background: #eef3e2;
    padding-bottom: 40px;
    padding-top: 40px;
    .part2tit {
      width: 1183px;
      height: 140px;
      margin: 0 auto;
      margin-bottom: 40px;
    }
    p {
      color: #286d5c;
      font-size: 42px;
      line-height: 70px;
    }
    .boxContent {
      margin: 0 auto;
      text-align: center;
      .jiantour {
        position: relative;
        top: -300px;
        margin: 0 0 0 50px;
      }
      div {
        display: inline-block;

        ul {
          width: 645px;
          li {
            display: inline-block;
          }
          li:nth-child(n) {
            margin-left: 40px;
            margin-bottom: 40px;
          }
        }
      }
    }
    .appCode {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 50px 0 30px;
      .appCode_left {
        display: flex;
        text-align: center;
        .codeBody {
          margin-right: 30px;
          >div:first-child {
            font-size: 20px;
            font-weight: 700;
            margin-bottom: 15px;
          }
          .codeBox {
            display: flex;
            .codeItem {
              &:first-child {
                margin-right: 18px;
                div {
                  background: #D7292A;
                }
              }
              .canvasCode {
                border-radius: 6px;
              }
              div {
                line-height: 48px;
                background: #0D686A;
                color: #fff;
                border-radius: 4px;
                font-size: 20px;
                margin-top: 6px;
              }
            }
          }
        }
      }
      .appCode_right {
        display: flex;
        align-items: center;
        &-tips {
          width: 412px;
          &-serve {
            color: red;
            border-bottom: 1px solid red;
            cursor: pointer;
          }
          &-item {
            p {
              line-height: normal;
              font-size: 18px;
              color: #2D2D2D;
              text-indent: 1.5em; /* 设置整体缩进 */
              &:first-child {
                text-indent: 0;
              }
            }
            
          }
        }
        &-serve {
          text-align: center;
          margin-left: 50px;
          img {
            cursor: pointer;
          }
          div {
            margin-top: 10px;
          }
        }
      }
      
    }
    .lastP {
      color: #286d5c;
      font-size: 42px;
      line-height: 70px;
      text-align: center;
    }
  }
  .activityDetailsDialog {
    width: 700px;
    height: 600px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    border-radius: 15px;
    box-sizing: border-box;
    padding: 20px;
    overflow-y: scroll;
    img {
      float: right;
      width: 20px;
    }
    h3 {
      color: #f04f54;
      margin: 10px 0;
    }
    p {
      line-height: 24px;
      span {
        font-weight: 600;
      }
    }
  }
}
</style>